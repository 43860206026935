import React from 'react';
import {Container, Typography, Alert, AlertTitle} from '@mui/material';
import CopyButton from './CopyButton';

const SubscriptionLink: React.FC = () => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const packageType = url.searchParams.get('package');

    return (
        <Container>
            {packageType === 'free' && (
                <Alert severity="success" style={{ marginBottom: '16px', marginTop: '24px'}}>
                    <AlertTitle> VPN رایگان</AlertTitle>
                    بسته ۱ ماهه با حجم 50GB برای شما با موفقیت ساخته شد.
                </Alert>
            )}
            <Typography variant="h6" paddingTop={2}>لینک اشتراک</Typography>
            <Typography variant="body1" paddingBottom={1}> روی دکمه کپی کلیک کنید تا لینک اشتراک شما کپی شود.</Typography>
            <CopyButton/>
        </Container>
    );
};

export default SubscriptionLink;
